<template>
  <div class="page post" :loaded="Boolean(page)">
    <section class="article" :type="slug" v-if="page">
      <div class="wrapper small">
        <!-- <div class="article-slug" v-if="slug === 'initiatives'">
          {{page.category}}
        </div>
        <div class="article-slug" v-else>
          {{page.category}} - {{page.title}}
        </div> -->
        <h3 class="article-title">
          <img class="article-logo" v-if="page.logo" :src="page.logo" :alt="page.title">
          {{page.title}}
        </h3>
        <div v-if="page.image">
          <div v-if="slug === 'news'" v-html="page.image" class="article-cover"></div>
          <img v-else :src="page.image" :alt="page.title" class="article-cover" />
        </div>
        <div class="article-content" v-html="page.content"></div>
        <div class="article-info buttons" v-if="slug === 'incubation'">
          <!-- <a class="btn ghost" v-if="page.email" :href="`mailto:${page.email}`">
            Contact their team
          </a> -->
          <a class="btn medium" rel="noopener" v-if="page.website" :href="page.website" target="_blank">
            Visit website
          </a>
        </div>
      </div>
    </section>

    <section class="partners" v-if="page && page.partners && page.partners.length">
      <div class="wrapper small">
        <h4>Project Funders</h4>
        <div class="partners-list">
          <a
            v-for="(partner, i) in page.partners"
            :key="`partner-${i}`"
            :href="partner.link || null"
            target="_blank"
            class="partner"
          >
            <img :src="partner.image" :title="partner.name" :alt="partner.name" />
          </a>
        </div>
      </div>
    </section>

    <section class="return">
      <router-link class="btn ghost" :to="`/${slug}`">
        Return to {{slug === 'initiatives' ? 'Collaborative projects' : slug}}
      </router-link>
      <a class="external-link" v-if="settings && slug === 'news'" :href="settings.medium" target="_blank">Find all our posts on Medium</a>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateFormat from "./DateFormat.vue";
import Icon from "./Icon.vue";

export default {
  name: "TemplatePost",
  components: {
    DateFormat,
    Icon,
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    pageId() {
      return this.$route.params.slug;
    },
    slug() {
      const params = this.$route.path.split("/");
      return params[1];
    },
    ...mapGetters({
      testMode: "getTest",
      settings: 'getSettings',
    }),
  },
  data() {
    return {
      page: null,
    };
  },
  watch: {
    pageId(id) {
      this.getPage(id);
    },
    testMode() {
      this.getPage(this.pageId);
    },
  },
  methods: {
    getPage(id) {
      if (this.pageRef) this.pageRef();
      if (this.slug === "events") {
        this.pageRef = this.db()
          .collection(`${this.testMode ? "test_" : ""}events`)
          .where("seo.slug", "==", id)
          .onSnapshot((events) => {
            if (events.size) {
              const event = events.docs[0];
              this.page = {
                title: event.get("title"),
                type: "event",
                content: event.get("description"),
                category: "Event",
                image: event.get("image"),
                placeholder: event.get("placeholder"),
                date: event.get("date"),
                link: window.location.href,
              };
              document.title = this.page.title;
            } else this.page = null;
          });
      } else if (this.slug === "incubation") {
        this.pageRef = this.db()
          .collection(`${this.testMode ? "test_" : ""}incubations`)
          .where("seo.slug", "==", id)
          .limit(1)
          .onSnapshot((incubations) => {
            if (incubations.size) {
              const incubation = incubations.docs.pop();
              this.page = {
                title: incubation.get("name"),
                content: incubation.get("description"),
                image: incubation.get("image"),
                placeholder: incubation.get("placeholder"),
                category: "Incubation",
                type: "incubation",
                email: incubation.get('email'),
                website: incubation.get('website'),
                link: window.location.href,
              };
              document.title = this.page.title;
            } else this.page = null;
          });
      } else if (this.slug === 'news') {
        this.pageRef = this.db()
          .collection(`${this.testMode ? "test_" : ""}posts`)
          .where("seo.slug", "==", id)
          .limit(1)
          .onSnapshot((posts) => {
            if (posts.size) {
              const post = posts.docs.pop();
              const content = document.createElement('div');
              content.innerHTML = post.get('content:encoded');
              const figure = content.querySelector('figure');
              let coverHTML = null;
              if (figure) {
                coverHTML = figure.outerHTML;
                figure.remove();
              }
              this.page = {
                title: post.get("title"),
                content: content.innerHTML,
                image: coverHTML,
                category: "News",
                type: "post",
                date: new Date(post.get('pubDate')),
                link: window.location.href,
              };
              document.title = this.page.title;
            } else this.page = null;
          });
      } else if (this.slug === 'initiatives') {
        const iid = id.split('-').pop();
        this.pageRef = this.db().doc(`${this.testMode ? "test_" : ""}initiatives/${iid}`).onSnapshot((post) => {
          if (!post.exists) {
            return this.page = null;
          }
          this.page = {
            title: post.get('name'),
            content: post.get('description'),
            image: post.get('background'),
            logo: post.get('image'),
            category: (post.get('categories') || []).join(' - '),
            type: 'initiative',
            link: window.location.href,
            partners: post.get('partners'),
          };
          document.title = this.page.title;
        });
      }
    },
  },
  mounted: function () {
    this.getPage(this.pageId);
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
  },
};
</script>

<style scoped>
.wrapper.small {
  width: 1024px;
}

.article[type="news"] .article-cover {
  margin-bottom: 40px;
}

.article:not([type="news"]) .article-cover,
.article .article-cover >>> img {
  display: block;
  width: 100%;
  /* height: 480px; */
  object-fit: cover;
  border-radius: 16px;
  margin: 24px 0;
}

.article .article-cover >>> figure {
  margin: 0;
}

.article .article-cover >>> figcaption {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #888;
  padding: 0 32px;
  margin-top: -12px;
}

.article .article-slug {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  color: #0A7E8C;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
}

.article[type="events"] .article-slug {
  color: var(--Metallic-Seaweed);
}

.article .article-slug:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
}

.article[type="events"] .article-slug:before {
  background-image: url(../assets/images/wb_o_4dots-o.svg);
}

.article .article-content {
  font-size: 20px;
  line-height: 1.1666666;
  margin-top: 32px;
}

.article .article-content >>> a {
  color: #0A7E8C !important;
}

#app[no-touch] .article .article-content >>> a:hover {
  text-decoration: underline;
}

.article .article-content >>> h1 {
  font-size: 64px;
}

.article .article-content >>> h2 {
  font-size: 48px;
}

.article .article-content >>> h3 {
  font-size: 32px;
}

.article .article-content >>> h4 {
  font-size: 24px;
}

.article .article-content >>> h5 {
  font-size: 20px;
}

.article .article-content >>> h6 {
  font-size: 16px;
}

.article .article-content >>> img {
  display: block;
  width: 100%;
}

.article .article-content >>> ul {
  list-style: initial;
  margin: initial;
  padding-left: 40px;
}

.article[type="news"] .article-content >>> p,
.article[type="news"] .article-content >>> ul,
.article[type="news"] .article-content >>> ol,
.article[type="news"] .article-content >>> figure {
  margin: 1em 0;
}

.article[type="news"] .article-content >>> figcaption {
  text-align: center;
  font-size: 14px;
  color: #888;
  margin-top: 8px;
}

.article[type="initiatives"] .article-title {
  display: flex;
  align-items: center;
  font-size: 40px;
  color: var(--Metallic-Seaweed);
}

.article[type="incubation"] .article-title {
  color: var(--Metallic-Seaweed);
}

.article .article-logo {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 24px;
}

.partners {
  margin-top: 64px;
}

.partners h4 {
  margin-bottom: 32px;
  text-align: center;
}

.partners-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.partners-list .partner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 200px;
  padding: 16px;
  border-radius: 16px;
  transition: all .2s ease-out;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
}

#app[no-touch] .partners-list .partner:hover {
  box-shadow: 0 24px 32px rgba(52, 52, 52, 0.12);
  transform: translateY(-8px);
}

.partners-list .partner img {
  display: block;
  width: 100%;
}

.return {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0 168px;
}

.return .external-link {
  display: block;
  margin-top: 24px;
  color: #0A7E8C;
  text-decoration: underline;
}

.return .external-link:hover {
  color: #1ba3cc;
}

@media screen and (max-width: 1450px) {
  .article:not([type="news"]) .article-cover {
    width: 100%;
    margin: 32px 0;
  }
}

@media screen and (max-width: 840px) {
  .partners-list .partner {
    width: 33.3333333%;
  }
}

@media screen and (max-width: 767px) {
  .article {
    margin: 0;
  }
  .article .article-slug {
    font-size: 18px;
  }
  .article .article-slug:before {
    width: 80px;
  }
}

@media screen and (max-width: 520px) {
  .article .article-title {
    font-size: 32px;
  }
  .article .article-slug {
    white-space: normal;
  }
  .partners-list .partner {
    width: 50%;
  }
}
</style>
